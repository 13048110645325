<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.editing }}</h4>
    <div class="admin-form-wrapper">
      <div v-if="quota" class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.quota }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="total" type="number" v-model="quota.total"/>
            </div>
            <ErrorMessage name="total" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.update }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      quota: null,
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post(`/quota/edit/${this.$route.params.id}`, this.quota).then(() => {
        this.$snotify.success(this.$lang.app.resource_updated_successfully);
        this.$router.push(`/admin/quotas/${this.quota.id}?type=${this.$route.query.type}`)
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error.response.data.message);
          actions.setErrors(error.response.data.errors);
        }
      })
    },
    fetchQuota() {
      this.$axios.get(`/quota/${this.$route.params.id}`).then(({data}) => {
        this.quota = data
      })
    }
  },
  mounted() {
    this.fetchQuota()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>